import React, { useEffect, useRef } from "react";
import "../styles/blocks/modal.scss";
/**
 * 
 * @param {*} props 
 * 
 * isOpen[boolean]: Etat ouvert/fermé
 * 
 * setIsOpen[function]: Setter de isOpen
 * 
 * handleKeyboard[function]: Gestion des events keyboard
 * 
 * children[JSX]: Contenu de la modal
 * 
 * @returns Composant Modal
 */
const Modal = (props) => {
  const {
    isOpen,
    setIsOpen,
    handleKeyboard,
    children
  } = props;

  const modalElement = useRef(null);

  useEffect(() => {
    if (isOpen) {
      modalElement.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return (<></>);
  }
 
  return ( 
    <>
      <div 
        className={"modal "+props.className}
        ref={modalElement}
        onKeyDown={ (event) => {
          event.preventDefault();
          if(event.keyCode == 27) {
            setIsOpen(false);
            return;
          }
          handleKeyboard(event);
        }}
        tabIndex="1"
      >
        <div 
          className="close-click-out" 
          title="Fermer"
          onClick={ () => setIsOpen(false) }
          tabIndex="1"
        ></div>
        <button 
          className="close" 
          title="Close" 
          onClick={ () => { setIsOpen(false); } }
        >❎</button>
        { children }
      </div>
    </>
  );
};

export default Modal;
